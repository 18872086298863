import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import ListWithSubgroup from '../../components/submenu/components/list-with-subgroup'
import ListWithoutSubgroup from '../../components/submenu/components/list-without-subgroup'

const Group = ({ data, parentTitle, id }) => {
  let menu
  const { t } = useTranslation()
  return (
    <div className="sitemap-page__group__container">
      <div className="row">
        {_.map(_.compact(data), (item) => {
          const title = _.get(item, 'title')
          if (Object.prototype.hasOwnProperty.call(item, 'subgroup')) {
            menu = (
              <ListWithSubgroup
                id={id}
                m={item}
                titleMenuSelected={parentTitle}
                submenutitle={title}
              />
            )
          } else {
            menu = (
              <ListWithoutSubgroup
                parentSlug={_.get(item, 'id') === 'experience' && _.get(item, 'id')}
                id={id}
                m={item}
                titleMenuSelected={parentTitle}
                submenutitle={title}
              />
            )
          }
          return (
            <>
              <div className="col-12 col-sm-6 col-md-3" key={title}>
                <div className="subgroup__title">
                  {' '}
                  {id === 'our-products'
                    ? item.title
                    : t(`menu.${parentTitle}.children.${title}.title`)}{' '}
                </div>
                {menu}
              </div>
            </>
          )
        })}
      </div>
    </div>
  )
}

export default Group
